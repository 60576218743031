@import "../../../assets/scss/abstract/variable";
.all-recipe-wrapper {
  .recipe-box,
  .video-box {
    img {
      height: 187px;
    }
  }
}

.fa-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: $color-placeholder;
}

@media (max-width: 575.98px) {
  .all-recipe-wrapper {
    .recipe-box {
      img {
        height: 125px;
      }
    }
  }
}
