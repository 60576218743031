@import "../../../assets/scss/abstract/variable";
.recipe-desc-wrapper {
  .main-content {
    .recipe-top-heading {
      padding-top: 100px;
    }
    .hometop-wrapper {
      display: none !important;
    }
    .recipe-image {
      height: 465px;
    }
    .video-box {
      iframe {
        width: 100%;
        height: 465px;
      }
    }
  } 
}

@media screen and (max-width: 767px) {
  .recipe-desc-wrapper {
    .main-content {
      .recipe-top-heading {
        font-size: $fs-7 !important;
        font-weight: 600 !important;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .recipe-desc-wrapper {
    .main-content {
      .recipe-image {
        height: auto;
      }
      .video-box {
        iframe {
          height: 228px;
        }
      }
    }
  }
}
