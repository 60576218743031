@import "../../../../assets/scss/abstract/variable";
.Subscription-popup {
  .modal-dialog {
    max-width: 650px!important;
    .modal-content {
      border-radius: 15px;
      .plan-price {
        font-size: $font-heading-1;
      }
    }
  }
  padding-right: 0 !important;
}
