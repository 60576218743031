.ReactTags__tags.react-tags-wrapper {
    background-color: white;
    /* align-items: center; */
    /* justify-content: center; */
    padding-left: 5px;
}

.tag-button{
    border-color: transparent;
    width:100%;
    margin-top: 5
}

.hide{
    display: none;
}
.show{
    display: block;
}

.ReactTags__tagInput {
    overflow: hidden;
}

.ReactTags__selected {
    display: flex;
}

button.ReactTags__remove {
    display: none;
}