@import "../../../../assets/scss/abstract/variable";
.user-header-wrapper {
  .top-strip,
  .hometop-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .primary-menu {
    li {
      a {
        color: #999999;
        &:hover,
        &.active {
          color: #883e7f;
          text-shadow: 0px 0px 5px rgb(136, 62, 127);
        }
      }
    }
  }
  .left-part {
    img {
      width: 70px;
    }
  }
  .signup-email {
    .modal-dialog {
      width: 100% !important;
      max-width: 90% !important;

      .modal-content {
        max-height: 90vh;
        overflow-y: auto;
      }
      .form-wraper {
        max-height: none !important;
        height: 100% !important;
      }
      .close-modal-option {
        left: 10px;
        top: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .signup-email {
    .display-img {
      // margin-top: 50px;
    }
  }
  // .modal {
  //   left: 15px !important;
  // }
}
