.CommunityTimeline-wrapper {
  .comments-recipe-tab.nav-tabs {
    .nav-link {
      padding: 5px 15px;
    }
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .sorting {
    .dropdown-toggle.btn {
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      background: #f3f3f3;
      border: 1px solid #d9d9d9;
    }
  }
  .dropdown-menu.show {
    margin-top: 5px;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2);
    border-radius: 5px;
    width: 100%;
  }
  .dropdown-item:hover {
    color:#87449A;
    background: rgba(135, 68, 154, 0.1);
  }
}

@media screen and (max-width: 500px) {
  .CommunityTimeline-wrapper {
    .comments-recipe-tab.nav-tabs {
      .nav-link {
        margin-right: 15px;
        padding: 0 !important;
      }
    }
  }
}
