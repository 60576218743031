.UserPosts-wrapper {


  .flowplayer{
    padding: 0;
    border-radius: 10px;
    max-height: 345px;
  }

  .fa-video-player .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 400px) {
  .UserPosts-wrapper {
    .comments-recipe-tab.nav-tabs {
      justify-content: center;
      .nav-link {
        margin-right: 12px;
      }
    }
  }
}
