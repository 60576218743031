.existing-features {
    .feature-box {
      max-width: 100%;
    }
  }

@media screen and (max-width: 576.5px) {
  .existing-features {
    .feature-box {
      max-width: 250px;
    }
  }
}
