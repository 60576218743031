@import "../../../../assets/scss/abstract/variable";
.community-content-box {
  border: 1px solid #d9d9d9;
  .header {
    .user-info {
      img {
        height: 48px;
        width: 48px;
        object-fit: cover;
        object-position: center;
      }
      .user-initials {
        height: 40px;
        width: 40px;
        font-size: 14px;
      }
    }
    img {
      height: 20px;
      width: 20px;
    }
  }
  p {
    line-height: 20px;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    inset: 0px auto auto 0px;
    transform: none !important;
    z-index: 0;
    min-width: 100px;
  }
  .thumbnail {
    max-height: 275px;
    min-height: 275px;
  }
  .report {
    img {
      transform: rotate(90deg);
      vertical-align: middle;
    }
  }
  .icon-wrapper {
    background-color: #f3f3f3;
    border: 1px solid #d9d9d9;
    .status-icon {
      vertical-align: middle;
    }
  }
}

.reasons-list {
  li:hover {
    background-color: $color-grey-3;
    width: 100%;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .content-box {
    .thumbnail {
      max-height: 370px;
      min-height: 370px;
    }
  }
}

@media screen and (min-width: 767.5px) {
  .content-box {
    .dropdown-menu {
      inset: 0px auto auto -62px !important;
    }
  }
}
