.upload-content{
    .upload-content-wrapper{
        .title-image-wrapper{
            .title-image{
                object-fit: cover;
                object-position: center;
                max-width: 109px;
                width: 100%;
                height: 66px;
            }
        }
            
    }  
}

.delete-action-popup{
    .modal-dialog{
        max-width: 400px;
        .modal-body{
            padding: 20px 40px 0;
        }
    }

}