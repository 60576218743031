@import "../../../assets/scss/abstract/variable";
.read-more-dashboard {
    .read-more-about-recipe {
        .lists-wrapper {
            // ul li::before {
            //     content: "\2022";
            //     color: $color-dark-7;
            //     font-weight: bold;
            //     display: inline-block;
            //     width: 1em;
            //     margin-left: -1em;
            // }
            // ol li{
            //     list-style: number;
            // }
        }
    }
    .main-content{
        padding-left: 50px !important;
        padding-bottom: 0px !important;
    }
}

@media (max-width: 991px) {
    .read-more-dashboard {
        .main-content {
            padding-left: 20px !important;
            padding-right: 20px !important;
            padding-bottom: 0px !important;
        }
    }
}

@media (max-width: 767px) {
    .read-more-dashboard {
        .main-content {
            padding-left: 15px !important;
            padding-right: 15px !important;
            padding-bottom: 0px !important;
        }
    }
}
