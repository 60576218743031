.user-home-page-dashboard {
    .main-content {
        padding-bottom: 0 !important;
    }
    
}

@media (max-width: 767px) {
    .user-home-page-dashboard {
        .copyright {
            padding-bottom: 70px;
        }
        .fs-48{
            font-size: 30px !important;
        }
        .fs-36{
            font-size: 25px !important;
        }
    }
    .user-header-wrapper + div {
        padding-top: 98px;
    }
   
}
