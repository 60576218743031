@import "../../../../assets/scss/abstract/variable";
.announcements-wrapper {
    .right {
        height: 330px;
        .action-btns {
            left: 20px;
            bottom: 0;
        }
    }
    .custom-editor-wrapper {
        .custom-editor-toolbar::before {
            content: "Content of announcement";
            font-size: 16px;
            color: #000000;
            margin-right: 20px;
        }
        .custom-editor {
            min-height: 230px;
            max-height: 230px;
            overflow-y: auto;
        }
        .rdw-colorpicker-modal {
            width: 200px;
            height: auto;
            padding: 5px;
            .rdw-colorpicker-modal-options {
                overflow: auto;
            }
        }
    }
    .search-bar {
        height: 45px;
    }
    .new-content{
        height: 45px;
    }
    table {
        border-collapse: collapse;
        th,
        td {
            padding-bottom: 40px;
        }
    }
    .annoucement-desc {
        max-width: 390px;
        width: 390px;
        line-height: 15px;
    }
    .edit {
        width: 50px;
    }
}