.form-group {
  // common inputs ======
  .form-control {
    background-color: $color-grey-4;
    border-color: $color-grey-4;
    color: $color-black;
    font-weight: 700;
    font-size: $font-heading-6;
    &:focus {
      outline: 0;
      box-shadow: none;
      border-color: $color-purple !important;
    }
  }
  textarea.form-control:not([rows]) {
    height: 120px;
  }
  // input with custom placeholder(floating) =============
  &.with-placeholder {
    position: relative;
    background-color: $color-grey-5;
    border-radius: 10px;
    .placeholder-text {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      font-size: $font-small;
      color: rgba($color-black, 0.4);
      font-weight: 700;
      font-size: $base-font-size;
      z-index: 9;
    }
    .form-control {
      position: relative;
      z-index: 10;
      font-size: $fs-5;
      background-color: transparent;
      border: 0;
      color: $color-dark-3;
      padding: 22px 20px 5px;
      height: auto;
      &:focus,
      &:not(:placeholder-shown) {
        + .placeholder-text {
          top: 22%;
        }
      }
    }
    &.float-placeholder {
      .placeholder-text {
        top: 19%;
      }
    }
  }
}

.form-control:focus {
  border-color: $color-purple;
  box-shadow: none;
}

/* ================== placeholder color ==============  */

.form-control::-webkit-input-placeholder,
::-webkit-input-placeholder {
  /* Edge */
  color: $color-placeholder;
}

.form-control:-ms-input-placeholder,
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $color-placeholder;
}

.form-control::placeholder,
::placeholder {
  color: $color-placeholder;
}

.form-control::-moz-placeholder {
  color: $color-placeholder;
  opacity: 1;
}

// ===================== custom inputs ===============
// default violet
.custom-control {
  .custom-control-label {
    &:before {
      border-color: $color-purple;
    }
    &:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
  }
  .custom-control-input {
    &:focus ~ .custom-control-label::before {
      box-shadow: none !important;
      border-color: $color-purple;
    }
    &:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: $color-purple;
      background-color: $color-purple;
    }
  }
  // checkbox (blue colored) ======
  &.info {
    .custom-control-label {
      &:before {
        border-color: $color-blue;
      }
    }
    .custom-control-input {
      &:focus ~ .custom-control-label::before {
        border-color: $color-blue;
      }
      &:checked ~ .custom-control-label::before {
        border-color: $color-blue;
        background-color: $color-blue;
      }
    }
  }
  // checkbox (dark colored) ======
  &.dark {
    .custom-control-label {
      &:before {
        border-color: $color-black;
      }
    }
    .custom-control-input {
      &:focus ~ .custom-control-label::before {
        border-color: $color-black;
      }
      &:checked ~ .custom-control-label::before {
        border-color: $color-black;
        background-color: $color-black;
      }
    }
  }
  &.custom-radio {
    .custom-control-label {
      &:before {
        border-color: $color-grey-3;
        background-color: $color-grey-3;
      }
    }
    .custom-control-input {
      &:focus ~ .custom-control-label::before {
        border-color: $color-purple;
      }
      &:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: $color-purple;
        background-color: $color-purple;
      }
     &:not(:disabled):active~.custom-control-label::before {
       background-color: transparent !important;
     }
    }
    &.info {
      .custom-control-label {
        &:before {
          border-color: $color-grey-3;
          background-color: $color-grey-3;
        }
      }
      .custom-control-input {
        &:focus ~ .custom-control-label::before {
          border-color: $color-blue;
        }
        &:checked ~ .custom-control-label::before {
          color: #fff;
          border-color: $color-blue;
          background-color: $color-blue;
        }
      }
    }
  }
}

// square readio / color selectors
.custom-square-radio {
  input {
    position: absolute;
    transform: translateX(-2000000000px);
    &:checked + .color-box {
      border-color: red;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }
  .color-box {
    height: 32px;
    width: 32px;
    border: 1px solid transparent;
    display: inline-block;
    cursor: pointer;
  }
}

.input-invalid {
  border: 1px solid #ff3d00 !important;
  border-radius: 10px;
}

//radio for product color selector
.custom-color-radio {
  input {
    position: absolute;
    transform: translateX(-2000000000px);
    &:checked + .color-box {
      border-color: $color-yellow;
    }
  }
  .color-box {
    height: 55px;
    width: 55px;
    line-height: 55px;
    border: 1px solid $color-grey-11;
    display: inline-block;
    cursor: pointer;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

//radio for product size selector
.custom-size-radio {
  input {
    position: absolute;
    transform: translateX(-2000000000px);
    &:checked + .size-box {
      border-color: $color-yellow;
    }
  }
  .size-box {
    height: 30px;
    width: 30px;
    line-height: 30px;
    border: 1px solid transparent;
    display: inline-block;
    cursor: pointer;
  }
}
