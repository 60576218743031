.suggestions-wapper {
  .suggestion-list {
    max-height: 480px;
    overflow-y: auto;
  }
  .suggestion-detail {
    figure {
      flex: 0 0 130px;
      margin-right: 10px;
      img {
        width: 130px;
        height: 91px;
      }
    }
    .info {
      line-height: 12px;
    }
    .time {
      line-height: 9px;
    }
    .premium-tag {
      left: 0;
      bottom: 20px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      img {
        max-width: 15px;
        height: auto !important;
      }
    }
  }
}
