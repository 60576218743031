.homepage-content-wrapper {
  .form-control {
    &:-webkit-input-placeholder {
      /* Edge */
      color: rgba(0, 0, 0, 0.85);
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(0, 0, 0, 0.85);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.85);
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .btn-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .display-recipes {
    .display-recipe {
      height: 600px;
      overflow: hidden;
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
      .title-edit {
        position: absolute;
        bottom: 0px;
        left: 0;
        padding: 10px;
        &.active {
          color: white;
          background: rgb(0,0,0);
          background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7847514005602241) 100%);
        }
      }
      button {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .display-recipes-lists {
      .display-recipe {
        height: 375px;
        overflow: hidden;
        img {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  .expert-section {
    .expert-video {
      height: 290px;
    }
  }
  .app-section {
    .app-photo {
      height: 400px;
    }
  }
  .features-content {
    .feature-box {
      .icon {
        img {
          max-height: 50px;
        }
      }
    }
  }
  .social-media-content {
    .desc {
      resize: none;
    }
    .social-media-links {
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 35px;
        }
      }
      .form-control {
        height: 50px;
        &:focus {
          background-color: #f3f3f3;
        }
      }
    }
  }
  .gallery-content {
    .gallery-box {
      height: 450px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
      &.small {
        height: 221px;
      }
    }
  }
  .subscribe-content {
    max-height: 350px;
    overflow: hidden;
    .inner-image-1 {
      height: 330px;
      border-radius: 100px;
      transform: rotate(-25deg);
      max-width: 200px;
      max-height: 350px;
      div {
        height: 100%;
        transform: rotate(25deg);
      }
    }
    .inner-image-2 {
      height: 450px;
      border-radius: 100px;
      transform: rotate(-25deg) translatey(-22px);
      max-width: 200px;
      max-height: 400px;
      div {
        height: 100%;
        transform: rotate(25deg);
      }
    }
  }
 
}

@media (max-width: 767px) {
  .homepage-content-wrapper {
    .display-recipes {
      .display-recipe {
        height: 320px;
      }
      .display-recipes-lists {
        .display-recipe {
          height: 250px;
        }
      }
    }
    .expert-section {
      .expert-video {
        height: 290px;
      }
    }
    .app-section {
      .app-photo {
        height: 320px;
      }
    }
    .features-content {
      .feature-box {
        .icon {
          img {
            max-height: 50px;
          }
        }
      }
    }
    .social-media-content {
      .social-media-links {
        .icon {
          img {
            max-height: 35px;
          }
        }
        .form-control {
          height: 57px;
          &:focus {
            background-color: #f3f3f3;
          }
        }
      }
    }
    .gallery-content {
      .gallery-box {
        height: 320px;
        &.small {
          height: 156px;
        }
      }
    }
    .subscribe-content {
      max-height: none;
      overflow: hidden;
      .inner-image-1 {
        height: 330px;
        border-radius: 100px;
        transform: rotate(-25deg) translatey(35px);
        max-width: 200px;
        max-height: 275px;
        div {
          height: 100%;
          transform: rotate(25deg);
        }
      }
      .inner-image-2 {
        height: 450px;
        border-radius: 100px;
        transform: rotate(-25deg) translatey(25px);
        max-width: 200px;
        max-height: 320px;
        div {
          height: 100%;
          transform: rotate(25deg);
        }
      }
    }
  }
}
