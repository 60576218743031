.content-management {
    .search-and-filter {
        .search-filter {
            .dropdown{
                // min-width: 200px;
                .filter-toggler.dropdown-toggle.btn::after{
                    display: none !important;
                }
            }
        }
        .fa-search {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
        }
    }
}