.watch-tutorials {
  .premium-icon {
    display: none;
    &.active {
      top: 7px;
      left: 7px;
      z-index: 4;
      display: block;
    }
  }
  .watch-tutorials-banner {
    position: relative;
    height: 400px;
    .react-player {
      position: absolute;
      left: 0;
      top: 0;
      iframe,
      video {
        height: 400px !important;
        width: 100% !important;
      }
    }
    .img {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 400px;
        width: 100%;
        opacity: 0.6;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
      }
    }
  }
  .tutorial-slider-wrapper {
    .slick-list {
      margin: 0 -10px;
      .slick-slide {
        padding: 0 10px;
        .tutorials-wrapper {
          overflow: hidden;
          .tutorials-img-wrapper {
            img {
              max-height: 250px;
              height: 250px;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .tutorials-content-wrapper {
            height: 200px;
            .recipe-title {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-right: 5px;
            }
            .play-button-img {
              position: relative;
              height: 50px;
              width: 50px;

              /* pulse wave */
              &:before {
                content: "";
                position: absolute;
                width: 75px;
                height: 75px;
                -webkit-animation-delay: 0s;
                animation-delay: 0s;
                -webkit-animation: pulsate1 2s;
                animation: pulsate1 2s;
                -webkit-animation-direction: forwards;
                animation-direction: forwards;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
                -webkit-animation-timing-function: steps;
                animation-timing-function: steps;
                opacity: 1;
                border-radius: 50%;
                border: 5px solid rgba(255, 255, 255, 0.75);
                top: -12.5px;
                left: -12.5px;
                background: rgba(198, 16, 0, 0);
              }
              img{
                position: relative;
              z-index: 9;
              }
            }
          }
        }
      }
    }
  }

  .slick-next {
    right: -28px !important;
  }
  .slick-prev {
    left: -28px !important;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2);
    opacity: 0;
  
    box-shadow: inset 0px 0px 25px 3px rgba(135, 68, 154, 0.75), 0px 0px 25px 10px rgba(135, 68, 154, 0.75);
  }
}