@import "../../../../../assets/scss/abstract/variable";
.Recipe-tabs-wrapper.inner-tabs {
  .nav.nav-pills {
    .nav-link {
      color: $color-black;
      font-size: $font-heading-6;
      font-weight: bold;
      padding: 11px 30px;
      background-color: $color-grey-6;
      // margin-right: 25px;
      border-radius: 10px;
      // max-width: 365px;
      width: 100%;
      border: none;
      &.active {
        background-color: $color-grey-2;
        color: $color-black;
        &::before {
          content: none;
        }
      }
      &:focus,
      &:hover {
        background-color: $color-grey-2;
      }
    }
  }
  .accordion {
    .card {
      .card-header {
        border-bottom: transparent;
        background-color: $color-grey-6;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .Recipe-tabs-wrapper.inner-tabs {
    .accordion {
      .card {
        margin-bottom: 20px;
      }
    }
  }
}
