@import "../../../assets/scss/abstract/variable";
.forget-password-wrapper {
  .btn-back {
    position: absolute;
    left: 30px;
    top: 30px;
  }
  h2 {
    color: $color-purple;
  }
  h5{
      color: $color-dark-3;
  }
}

@media screen and (max-width: 767px){
    .forget-password-wrapper {
      .btn-back{
         position: static;
        }       
      }
}