.collection-wrapper {
  .collection-slide {
    margin-bottom: 20px;
    img {
      height: 271px;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    h4{
      min-height: 50px;
    }
  }
  .collection-slide {
    .img-box {
      overflow: hidden;
      &:hover {
        img {
          transform: scale(1.1);
          transition: all 300ms ease-in;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .collection-wrapper {
    .collection-slide {
      max-width: 100%;
    }
  }
}
