@import "../../../../assets/scss/abstract/variable";
.reports-review-management {
  .ant-pagination-simple {
    display: flex;
    align-items: center;
    border: 1px solid #dee2e6;
    border-radius: 0.25em;
    .ant-pagination-simple-pager {
      position: relative;

      padding: 0.5rem 0.5rem;
      margin: 0px;
      line-height: 1.25;
      color: $color-purple;
      background-color: #fff;
      border-right: 1px solid #dee2e6;
      border-left: 1px solid #dee2e6;
      height: auto;
      input {
        border: 0;
        padding: 0;
        margin: 0;
      }
      .ant-pagination-slash {
        margin: 0;
      }
    }
    .ant-pagination-prev {
      color: $color-purple;
      margin: 0;
      height: 100%;
      .ant-pagination-item-link{
        height: 100%;
      }
    }
    .ant-pagination-next {
      margin: 0;
      color: $color-purple;
      height: 100%;
      .ant-pagination-item-link {
        height: 100%;
      }
    }
  }
  .fa-search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
}
