@import "../../../../assets/scss/abstract/variable";

@media screen and (max-width: 767px) {
  .faq-accordion {
    .accordion.outer-tabs {
      .card-header {
        background-color: $color-grey-4;
        color: $color-dark;
        font-size: $font-heading-5;
        font-weight: 600;
        border-bottom: transparent;
        border-radius: 6px;
        &.active {
          background-color: $color-grey-3;
          border-radius: 6px;
        }
      }
      .card-body {
        border-bottom: transparent;
        border-radius: 0;
      }
      .inner-accordion {
        .card-header {
          padding: 15px 0;
          background-color: $color-white;
          margin-bottom: 0 !important;
          border-bottom: 1px solid $color-dark-purple;
          border-radius: 0;
        }
        .card-body {
          padding: 20px 0;
          border-bottom: 1px solid $color-dark-purple;
          .topic-desc {
            line-height: 20px !important;
          }
        }
      }
    }
  }
}