@import "../../../../../assets/scss/abstract/variable";
.content-listing-management {
  .dropdown-toggle::after {
    display: none !important;
  }

  .listing-table {
    .custom-control.custom-radio {
      width: 32px;
      height: 33px;
      cursor: pointer;
      .custom-control-input {
        &:focus ~ .custom-label {
          border-color: $color-purple;
        }
        &:checked ~ .custom-label {
          border-color: $color-red;
        }
      }
    }
    thead {
      border: 2px solid $color-purple;
    }

    .listing-table-data {
      min-width: 350px;
      max-width: 350px;
      width: 350px;
      line-height: 1.3;
      .card-header {
        background-color: transparent;
        p {
          word-break: break-word;
          white-space: normal;
          width: 100%;
          position: relative;
          padding-right: 20px;
          &::after {
            content: " ";
            position: absolute;
            right: 0;
            top: 50%;
            width: 2px;
            height: 2px;
            display: inline-block;
            border: 5px solid transparent;
            border-top-color: $color-black;
            transform: translateY(-50%);
          }
        }
      }
      .title {
        flex: 0 0 450px;
      }
    }
    .item-image {
      width: 109px;
      height: 66px;
      object-fit: cover;
      object-position: center;
    }
  }

  .carousel-selection-list {
    .search {
      height: 45px;
      &::placeholder {
        color: $color-black;
      }
    }
    .filter-toggler {
      width: 273px !important;
      height: 45px;
    }
  }
}
