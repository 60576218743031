.contact-us {
    margin-top: 60px;
    .bottom-menu{
        li{
            a{
                color: #424242 ;
                &:hover{
                    color:#883e7f;
                }
            }
        }
    }
    .social-media-icons {
        li{
            a{
                transition: all 300ms ease-in;
                &:hover{
                    transform: translateY(-10px);
                }
            }
            
            
        }
    }
}

@media (max-width: 991px) {
    .contact-us {
        // margin: 70px -20px 0px -20px;
    }
}
@media (max-width: 767px) {
    .contact-us {
        // margin: 50px -15px 0px -15px;
    }
}
