@import "../../../../assets/scss/abstract/variable";
.recipe-section {
  .recipe-top-section {
    .left-section {
      max-height: 675px;
      border-radius: 20px;
      overflow: hidden;
      img {
        width: 100%;
        min-height: 675px;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 20px;
        opacity: 0.6;
        background: linear-gradient(
          180deg,
          rgba(66, 66, 66, 0) 0%,
          #000000 100%
        );
      }
      .premium {
        border-radius: 0px 3px 3px 0px;
        top: 30px;
        left: 0;
      }
      .recipe-info {
        left: 0px;
        bottom: 20px;
        width: 100%;
        z-index: 2;
        padding: 10px;
      }
    }
    .right-section {
      max-height: 675px;
      overflow: hidden;
      overflow-y: auto;
      height: 100%;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(131, 16, 197, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(131, 16, 197, 0.1);
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(131, 16, 197, 0.4);
      }
      &::-webkit-scrollbar-thumb:active {
        background: rgba(131, 16, 197, 0.9);
      }
      .recipe-content-wrapper {
        overflow: hidden;
        border-radius: 20px;
        // max-height: 387px;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0.6;
          background: linear-gradient(
            180deg,
            rgba(66, 66, 66, 0) 0%,
            #000000 100%
          );
        }
        .overlay {
          height: 100%;
          width: 100%;
          background: linear-gradient(
            180deg,
            rgba(66, 66, 66, 0) 0%,
            #000000 100%
          );
          z-index: 3;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 300ms ease-in-out;
          .read-more-btn {
            background-color: transparent !important;
            border: 1px solid #fff !important;
            z-index: 2;
            color: #ffffff;
            &:hover {
              background-color: #ffffff !important;
              color: #000000;
            }
          }
        }
        &:hover .overlay {
          opacity: 1;
          visibility: visible;
          .recipe-info {
            display: block;
          }
        }
        .recipe-image {
          img {
            max-height: 387px;
            width: 100%;
            min-height: 387px;
            border-radius: 20px;
          }
        }

        .premium-icon {
          display: none;
          &.active {
            top: 7px;
            left: 7px;
            z-index: 4;
            display: block;
          }
        }

        .recipe-title {
          bottom: 10px;
          left: 10px;
          z-index: 3;
        }
      }
    }
    .img-box {
      line-height: 0;
    }
  }
  .slick-dots {
    li {
      width: auto;
      height: 5px;
      margin: 0 5px;

      button {
        width: 50px;
        height: 5px;
        background: #f3f3f3;
        padding: 0;
        border-radius: 2px;
        &::before {
          content: none;
        }
      }
      &.slick-active {
        button {
          background: #87449a;
        }
      }
    }
  }
  // -------------------------recipe-bottom-section -------start

  .recipe-bottom-section {
    .recipe-type {
      .nav-tabs {
        border-bottom: none;
        .nav-link {
          text-align: center;
          padding: 10px;
          border-bottom: 5px solid $color-grey-12;
          flex-grow: 1;
          font-size: 24px;
          font-weight: 500;
          &.active,
          &:hover,
          &:focus {
            border: 0;
            border-bottom: 5px solid $color-purple;
          }
        }
      }
    }

    .tab-content {
      margin-top: 40px;
    }

    .recipe-section {
      .recipe-img-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 250px;
        img {
          width: 100%;
          max-height: 250px;
          min-height: 250px;
          object-fit: cover;
          object-position: center;
        }
      }
      .recipe-content-wrapper {
        height: 180px;
        .recipe-title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .slick-slider {
        .slick-prev.slick-arrow,
        .slick-next.slick-arrow {
          display: none !important;
        }
      }
      .premium-icon {
        display: none;
        &.active {
          top: 7px;
          left: 7px;
          z-index: 4;
          display: block;
        }
      }
    }
  }
  .recipe-section {
    .slick-list {
      margin: 0 -10px;
      .slick-slide {
        padding: 0 10px;
        .recipe-wrapper {
          overflow: hidden;
        }
      }
    }
  }

  // -------------------------recipe-bottom-section -------end
}
.signup-email-popup {
  z-index: 99999 !important;
  .close-modal-option {
    left: 10px;
    top: 10px;
    cursor: pointer;
  }
  .common-login {
    max-height: 95vh;
    .display-img {
      max-height: 95vh;
      img {
        max-height: 95vh;
      }
    }
    .form-wraper {
      max-height: 95vh;
    }
  }
  .modal-dialog {
    max-width: 95% !important;
  }
}
@media (max-width: 991px) {
  .recipe-section {
    .recipe-top-section {
      .left-section {
        max-height: none;
        img {
          min-height: 400px;
        }
      }
      .right-section {
        max-height: none;
        overflow: visible;
        overflow-y: visible;
        height: auto;
        .recipe-content-wrapper {
          overflow: auto;
          border-radius: 20px;
        }
      }
    }
    .recipe-bottom-section {
      .nav-tabs.nav {
        flex-wrap: nowrap;
        font-size: $base-font-size;
        padding-bottom: 10px;

        .nav-link {
          padding: 0.5rem 0.5rem;
        }
      }
    }

    .recipe-bottom-section {
      .recipe-type {
        .nav-tabs {
          border-bottom: none;
          .nav-link {
            padding: 10px;
            border-bottom: 2px solid $color-grey-12;

            font-size: 16px;

            &.active,
            &:hover,
            &:focus {
              border: 0;
              border-bottom: 2px solid $color-purple;
            }
          }
        }
      }

      .tab-content {
        margin-top: 40px;
      }
    }
  }
  .slick-dots {
    li {
      width: auto;
      height: 5px;
      margin: 0 5px;

      button {
        width: 20px;
        height: 5px;
        background: #f3f3f3;
        padding: 0;
        border-radius: 2px;
        &::before {
          content: none;
        }
      }
      &.slick-active {
        button {
          background: #87449a;
        }
      }
    }
  }

  .signup-email-popup {
    z-index: 99999 !important;
    .close-modal-option {
      left: 10px;
      top: 10px;
      cursor: pointer;
    }
    .common-login {
      max-height: 80vh;
      .display-img {
        display: none;
      }
    }
    .modal-dialog {
      max-width: 95% !important;
    }
  }
}
@media (max-width: 542px) {
  .recipe-section {
    .recipe-bottom-section {
      .nav-tabs.nav {
        justify-content: center !important;
        .nav-link {
          padding: 0.5rem 0.5rem;
        }
      }
    }
  }
}
@media (max-width: 440px) {
  .recipe-section {
    .slick-dots {
      li {
        &:nth-child(even) {
          display: none;
        }
      }
    }
  }
}
