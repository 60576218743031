/********* Buttons ******************/
.btn {
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    text-decoration: none !important;
    font-weight: 700;
    font-size: $font-heading-6;
    padding: 15px 35px;
    border-radius: 30px;
    &:focus {
        outline: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    // button dark (black background)
    &.btn-dark {
        background-color: $color-dark-2 !important;
        border-color: $color-dark-2 !important;
        color: $color-white !important;
        &:hover,
        &:focus {
            background-color: $color-black !important;
            border-color: $color-black !important;
            color: $color-white !important;
        }
    }
    // button light (light grey background)
    &.btn-light {
        background-color: $color-grey-3 !important;
        border-color: $color-grey-3 !important;
        color: $color-black !important;
        &:focus,
        &:hover {
            background-color: $color-dark-2 !important;
            border-color: $color-dark-2 !important;
            color: $color-white !important;
        }
    }
    // button info (light blue background)
    &.btn-info {
        background-color: $color-blue !important;
        border-color: $color-blue !important;
        color: $color-white !important;
        &:focus,
        &:hover {
            background-color: $color-sky-blue !important;
            border-color: $color-sky-blue !important;
            color: $color-white !important;
        }
    }
    // button primary (violet background)
    &.btn-primary {
        background-color: $color-purple !important;
        border-color: $color-purple !important;
        color: $color-white !important;
        &:focus,
        &:hover {
            background-color: $color-dark-purple !important;
            border-color: $color-dark-purple !important;
            color: $color-white !important;
        }
    }
    // button warning ( yellow background)
    &.btn-warning {
        background-color: $color-yellow !important;
        border-color: $color-yellow !important;
        color: $color-white !important;
        &:hover,
        &:focus {
            background-color: $color-light-yellow !important;
            border-color: $color-light-yellow !important;
            color: $color-white !important;
        }
    }
    // button warning outline  ( yellow border background)
    &.btn-outline-warning {
        border-color: $color-yellow !important;
        color: $color-black !important;
        &:hover,
        &:focus {
            background-color: $color-light-yellow !important;
            border-color: $color-light-yellow !important;
            color: $color-white !important;
        }
    }
    // button dark outline  ( dark border background)
    &.btn-outline-dark {
        border-color: $color-black !important;
        color: $color-black !important;
        &:hover,
        &:focus {
            background-color: $color-black !important;
            border-color: $color-black !important;
            color: $color-white !important;
        }
    }
    // button info  outline  ( blue border background)
    &.btn-outline-info {
        border-color: $color-blue !important;
        color: $color-black !important;
        &:hover{
            background-color: $color-blue !important;
            border-color: $color-blue !important;
            color: $color-white !important;
        }
         &:focus {
            border-color: $color-blue !important;
            background-color: $color-white !important;
            color: $color-black !important;
        }
    }

    /// button success ( green background)
    &.btn-success {
        border-color: $color-green-4 !important;
        color: $color-white !important;
        &:hover,
        &:focus {
            background-color: $color-green-3 !important;
            border-color: $color-green-3 !important;
            color: $color-white !important;
        }
    }
    // button info  outline  ( purple border background)
    &.btn-outline-purple {
        border-color: $color-purple !important;
        color: $color-purple !important;
        background-color: transparent !important;
        &:hover{
            background-color: $color-purple !important;
            border-color: $color-purple !important;
            color: $color-white !important;
        }
        &:focus {
            border-color: $color-purple !important;
            color: $color-purple !important;
            background-color: transparent !important;
        }
    }
    &.btn-outline-white {
        border-color: $color-white !important;
        color: $color-white !important;
        background-color:transparent !important;
        &:hover,
        &:focus {
            background-color: $color-white !important;
            border-color: $color-white !important;
            color: $color-black !important;
        }
    }
}
