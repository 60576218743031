.food-gallery {
  .images-wrapper-outer {
    .first-wrapper {
      flex: 0 0 40%;
      max-width: 40%;
      [class*="img-"]:not(.img-fluid) {
        height: 400px;
        background: #f3f3f3;
        border-radius: 20px;
        overflow: hidden;
        flex: 0 0 100%;
        max-width: 100%;
        img {
          height: 400px;
          // width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .second-wrapper {
      flex: 0 0 60%;
      max-width: 60%;
      [class*="img-"]:not(.img-fluid) {
        height: 400px;
        background: #f3f3f3;
        border-radius: 20px;
        overflow: hidden;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        img {
          height: 400px;
          object-fit: cover;
          object-position: center;
        }
        &.img-2,
        &.img-3 {
          height: 196px;
          flex: 0 0 100%;
          max-width: 100%;

          img {
            height: 196px;
            // width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .food-gallery {
    .images-wrapper-outer {
      flex-wrap: wrap;
      .first-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        [class*="img-"]:not(.img-fluid) {
          height: 400px;
          background: #f3f3f3;
          border-radius: 20px;
          overflow: hidden;
          flex: 0 0 100%;
          max-width: 100%;
          img {
            height: 400px;
            // width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      .second-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        [class*="img-"]:not(.img-fluid) {
          height: 400px;
          background: #f3f3f3;
          border-radius: 20px;
          flex: 0 0 33.33%;
          max-width: 33.33%;
          img {
            height: 400px;
          }
          &.img-2,
          &.img-3 {
            height: 196px;
            flex: 0 0 100%;
            max-width: 100%;

            img {
              height: 196px;
              // width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .food-gallery {
    .images-wrapper-outer {
      flex-wrap: wrap;
      overflow: hidden;
      .first-wrapper {
        [class*="img-"]:not(.img-fluid) {
          height: 250px;
          background: #f3f3f3;
          border-radius: 10px;
          overflow: hidden;
          flex: 0 0 100%;
          max-width: 100%;
          img {
            height: 250px;
            // width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      .second-wrapper {
        flex-wrap: wrap;
        max-width: 98% !important;
        justify-content: space-between;
        flex: 0 0 98%;
        [class*="img-"]:not(.img-fluid) {
          height: 200px;
          background: #f3f3f3;
          border-radius: 10px;
          overflow: hidden;
          flex: 0 0 50% !important;
          max-width: 48% !important;
          img {
            height: 200px;
          }
        }
        .images-wrapper-inner {
          display: flex;
          flex: 0 0 100%;
          .img-2,
          .img-3 {
            height: 196px;
            flex: 0 0 50% !important;
            max-width: 48% !important;

            img {
              height: 196px;
              // width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
}
