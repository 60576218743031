.user-notification-wrapper {
  .hometop-wrapper {
    display: none !important;
  }
  .user-header-wrapper + div {
    padding-top: 100px;
  }
  .notification-box {
    .related-img {
      flex: 0 0 75px;
      max-width: 75px;
      img {
        height: 42px;
        width: 75px;
        object-fit: cover;
        object-position: center;
      }
    }
    .post-img {
      width: 45px;
      height: 45px;
    }
  }
}
