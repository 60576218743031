.admin-home-dashboard.common-dashboard {
    .sidebar-menu {
        z-index: 999;
        width: 58px;
        top: 0;
        ul {
            li+li {
                margin-top: 60px;
            }
        }
    }
    .main-content {
        padding-left: 110px!important;
        padding-right: 48px !important;
        padding-bottom: 48px !important;
        min-height: 100vh;
    }
}

@media (max-width: 991px) {
    .admin-home-dashboard.common-dashboard {
        .main-content {
            padding-left: 75px!important;
            padding-right: 20px !important;
            padding-bottom: 20px !important;
        }
    }
}

@media (max-width: 767px) {
    .admin-home-dashboard.common-dashboard {
        .sidebar-menu {
            z-index: 999;
            width: 0;
            top: auto;
            left: 0;
            width: 100%;
            height: auto!important;
            bottom: 0;
            border-radius: 10px 10px 0px 0px;
            ul {
                padding: 10px 20px;
                li+li {
                    margin-top: 0px;
                    margin-left: 10px;
                }
            }
        }
        .main-content {
            padding-left: 15px !important;
            padding-right: 15px !important;
            padding-bottom: 70px !important;
        }
    }
}

// new .common-dashboard
.common-dashboard {
  .sidebar-menu {
    z-index: 999;
    width: 58px;
    ul {
      li + li {
        margin-top: 60px;
      }
    }
  }
  .main-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 48px;
    min-height: 100vh;
    max-width: 1470px;
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .common-dashboard {
    .main-content {
      // padding-left: 75px;
      // padding-right: 20px;
      // padding-bottom: 20px;
    }
  }
}

@media (max-width: 767px) {
  .common-dashboard {
    .sidebar-menu {
      z-index: 9;
      width: 100%;
      height: auto !important;
      bottom: 0;
      border-radius: 10px 10px 0px 0px;
      ul {
        padding: 10px 20px;
        li + li {
          margin-top: 0;
          margin-left: 10px;
        }
      }
    }
    .main-content {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 70px;
    }
  }
}
