.faqs-topic {
    .custom-editor-wrapper {
        .custom-editor-toolbar::before {
            content: "Answer";
            font-size: 16px;
            color: #000000;
            margin-right: 50px;
        }
        .custom-editor {
            min-height: 180px;
            max-height: 180px;
            overflow-y: auto;
            border-radius: 5px;
        }
       
    }
}