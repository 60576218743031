.top-search-wrapper {
    .form-group {
        .form-control {
            padding: 8px 0;
            // max-width: 873px;
            height: 45px;
        }
    }
}

@media (max-width:576px) {
    .top-search-wrapper {
        .form-group {
            .form-control {
                padding: 10px 0;
            }
        }
    }
}