.saved-video {

  .saved-video-wrapper {
    .saved-video-box {
      .saved-vdo-img {
        max-width: 190px;
        max-height: 132px;
        height: 132px;
      }
    }
  }
  .hometop-wrapper {
    display: none !important;
  }
  .user-header-wrapper + div {
    padding-top: 105px !important;
  }
  .remove-video {
    flex: 0 0 25px;
  }
  .video {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media screen and (max-width: 576px) {
  .saved-video {
    .saved-video-wrapper {
      .saved-video-box {
        .saved-vdo-img {
          max-width: 130px;
          max-height: 91px;
          height: 91px;
        }
      }
    }

    .user-header-wrapper + div {
      padding-top: 105px !important;
    }
  }
}
