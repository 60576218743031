.meet-expert {
    .meet-expert-banner {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0.6;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 20px;
        }
    }
}
