.grocery-list-items{
    background-color: #fff;
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 5px;
    box-shadow: 4px 5px #F3E9DD;
    align-items: center;
    font-weight: 500;
}

.grocery-list-card{
    margin-bottom: 8px;
    border-radius: 5px;
    box-shadow: 4px 5px #EDE6DB;

    
}


