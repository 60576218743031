@import "../../../../assets/scss/abstract/variable";
.admin-top-tabs {
  padding: 15px 0;
  top: 0;
  width: 1470px;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 110px;
  z-index: 99;
  li {
    a {
      color: #000000;
      border: 1px solid transparent;
      border-radius: 4px;
      margin-right: 2px;
      padding: 0.5rem 1rem;
      &:hover,
      &.active {
        border: 1px solid $color-purple;
      }
    }
  }
  & + div {
    padding-top: 100px !important;
  }
}


@media (max-width: 992px) {
  .admin-top-tabs {
    padding-left: 95px;
  }
}

@media (max-width: 767.5px) {
  .admin-top-tabs {
    padding-left: 20px;
  }
}

@media (max-width: 576px){
  .admin-top-tabs {
    padding-left: 20px;
    & + div {
      padding-top: 120px !important;
    }
  }
}