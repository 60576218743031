.collections-category-wrapper {
  .top-search-wrapper {
    input {
   
      height: 45px !important;
    }
  }
  .notification-wrapper {
    display: none !important;
  }
  .collection-wrapper {
    display: flex;
    justify-content: space-between;

    .nav-tabs {
      flex: 0 0 225px;
      max-width: 225px;
      border-right: 1px solid #dee2e6 !important;
      padding-left: 10px;
      padding-right: 10px;
      flex-direction: column;
      align-items: flex-start;
      .nav-link {
        margin-right: 0;
      }
    }
    .tab-content {
      flex-grow: 1;
    }

    .Product-wrapper {
      margin-top: 0;
    }
  }
}

@media (max-width: 767px) {
  .collections-category-wrapper {
    .top-search-wrapper {
      input {
   
        height: 45px !important;
      }
    }
    .notification-wrapper {
      display: none !important;
    }
    .collection-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .nav-tabs {
        flex: 0 0 100%;
        max-width: 100%;
        border-right: 0 !important;
        padding-left: 10px;
        padding-right: 10px;
        top: 0;
        left: -100%;
        width: 100%;
        background-color: #ffffff;
        z-index: 999;
        height: 100%;
        padding: 50px 25px;
        position: fixed;
        transition: all 300ms ease-in;
        .nav-link {
          margin-right: 0;
        }
      }
      .tab-content {
        flex-grow: 1;
      }
      .Product-wrapper {
        margin-top: 0;
      }
      .close-side-bar {
        position: fixed;
        top: 15px;
        right: 15px;
        z-index: 9999;
        display: none;
      }
      &.active-sidebar{
        .nav-tabs{
          left: 0;
        }
        .close-side-bar{
          display: inline-block;
        }
      }
    }
  }
}

@media (max-width: 567px) {
  .collections-category-wrapper {
    .collection-wrapper {
      padding-top: 100px;
      .comments-recipe-tab.nav-tabs {
        .nav-link {
          margin-right: 10px;
        }
      }
    }
  }
}
