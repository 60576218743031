.user-profile {
    .profile-section {
        .profile-img {
            max-width: 141px;
            max-height: 141px;
            .profile-pic {
                max-width: 141px;
                max-height: 141px;
                height: 141px;
            }
            .camera-edit {
                max-width: 40px;
                height: 40px;
                border-radius: 50%;
                bottom: 0px;
                right: 0px;
            }
            .user-initials {
                max-width: 141px;
                max-height: 141px;
                height: 141px;
                font-size: 30px;
            }
        }
    }
    .hometop-wrapper {
        display: none !important;
    }
    a {
        line-height: 30px;
    }
}

@media screen and (max-width: 767px) {
    .edit-profile {
        .profile-section {
            .back-option {
                display: none;
            }
        }
    }
}