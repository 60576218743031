@import "../../../assets/scss/abstract/mixins";
.view-show-wrapper {
  .show-thumbnail {
    img {
      height: 500px;
      object-fit: cover;
      object-position: center;
    }
  }
  .suggestion-list {
    max-height: 480px;
    overflow-y: auto;
    @include flex;
    .suggestion-detail {
      @include flex-size(33.33%);
      padding: 10px;
      @include flex-direction(column);
      img {
        width: 100%;
        height: 200px;
      }
      .right {
        margin-top: 20px;
        h6 {
          font-size: 20px;
          margin-bottom: 10px;
        }
        .info {
          font-size: 18px !important;
          margin-bottom: 5px;
          line-height: 24px;
        }
        .time {
          font-size: 14px !important;
          line-height: 16px;
        }
      }
    }
  }
  &.show-list-wrapper {
    .suggestion-list {
      max-height: none;
      overflow-x: hidden;
    }
  }
}

@media screen and (max-width: 767px) {
  .view-show-wrapper {
    .show-thumbnail {
      img {
        height: auto;
      }
    }
    .suggestion-list {
      max-height: none;
      .suggestion-detail {
        @include flex-size(50%);
        img {
          height: 150px;
        }
        .right {
          margin-top: 20px;
          h6 {
            font-size: 14px;
            margin-bottom: 10px;
          }
          .info {
            font-size: 12px !important;
            line-height: 18px;
          }
          .time {
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .common-dashboard.view-show-wrapper .user-header-wrapper + div {
    padding-top: 100px;
  }

  .view-show-wrapper {
   
    .suggestion-list {

      .suggestion-detail {
        @include flex-size(100%);
       
      }
    }
  }
}
