.suggestions-wapper {
  .suggestion-list {
    max-height: 480px;
    overflow-y: auto;
  }
  .suggestion-detail {
    img {
      width: 130px;
      height: 91px;
    }
    .info {
      line-height: 12px;
    }
    .time {
      line-height: 9px;
    }
  }
}
