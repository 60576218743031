@import "../../../assets/scss/abstract/variable";

.user-home-page-dashboard.common-dashboard {
  .sidebar-menu {
    z-index: 10000;
  }
  .new-episodes-slider {
    padding-top: 40px !important;
  }
  .top-search-wrapper {
    .card {
    
      font-size: 12px;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 99;
      .list-group-flush > .list-group-item {
        padding: 5px 10px;
        font-weight: 500;
        &:hover {
          background-color: #883e7f;
          color: #ffffff;
        }
      }
    }
  }
  .fa-search {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-family: 'fontAwesome';
    color: $color-placeholder;
  }
}
