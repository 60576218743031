.admin-home-dashboard {
  .user-header-wrapper {
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    .left-part {
      img {
        width: 75px;
      }
    }
    // .hometop-wrapper {
    //   padding-left: 110px;
    //   padding-right: 48px;
    // }
    .right-part {
      .user-img {
        height: 47px;
        width: 47px;
        object-fit: cover;
        object-position: center;
      }
      .user-initials {
        height: 47px;
        width: 47px;
      }
    }
  }
  .user-header-wrapper + div {
    padding-top: 110px;
  }
  .back-top-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .admin-home-dashboard {
    .user-header-wrapper {
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      .top-strip,
      .hometop-wrapper {
        padding-left: 75px;
        padding-right: 20px;
      }
    }
  }
}

@media (max-width: 767.5px) {
  .admin-home-dashboard {
    .user-header-wrapper {
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      .top-strip,
      .hometop-wrapper {
        padding-left: 0px;
        padding-right: 0px;
      }
      .right-part {
        .user-img {
          height: 35px;
          width: 35px;
          object-fit: cover;
          object-position: center;
        }
        .user-initials {
          height: 35px;
          width: 35px;
        }
      }
    }
    .back-top-btn {
      bottom: 50px;
    }
  }
}

@media (max-width: 576px) {
  .admin-home-dashboard {
    .user-header-wrapper + div {
      padding-top: 200px;
    }
  }
}

// new header settings ====================

.user-header-wrapper {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  .top-strip,
  .hometop-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .primary-menu {
    li {
      a {
        color: #999999;
        &:hover,
        &.active {
          color: #883e7f;
          text-shadow: 0px 0px 5px rgb(136, 62, 127);
        }
      }
    }
  }
  .right-part {
    .user-img {
      height: 47px;
      width: 47px;
      object-fit: cover;
      object-position: center;
    }
    .user-initials {
      height: 47px;
      width: 47px;
    }
    .notification-link {
      color: #999999;
    }
  }
  .dropdown-menu.show {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
    // box-shadow: 0 0 5px rgb(0 0 0 / 20%) !important;
    right: 0 !important;
    top: 100% !important;
    transform: none !important;
    a.dropdown-item {
      &:hover,
      &:active,
      &:focus {
        background-color: #883e7f !important;
        color: #ffffff;
      }
    }
  }
}

.user-header-wrapper + div {
  padding-top: 110px;
}

.back-top-btn {
  position: fixed;
  bottom: 30px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 25px;
}

@media (max-width: 991px) {
  .user-header-wrapper {
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    .top-strip,
    .hometop-wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media (max-width: 767.5px) {
  .user-header-wrapper {
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    .top-strip,
    .hometop-wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
    .right-part {
      .user-img {
        height: 35px;
        width: 35px;
        object-fit: cover;
        object-position: center;
      }
      .user-initials {
        height: 35px;
        width: 35px;
      }
      .menu-open {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .primary-menu {
    position: fixed;
    flex-direction: column;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 300ms ease-in;
    z-index: 9999;
    &.active {
      left: 0;
    }
    .menu-close {
      top: 20px;
      left: 260px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
    ul {
      max-width: 300px;
      height: 100%;
      background-color: #ffffff;
      padding: 50px 20px;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      li {
        padding: 10px 0;
      }
    }
  }
  .back-top-btn {
    bottom: 50px;
  }
}

@media (max-width: 576px) {
  .user-header-wrapper + div {
    padding-top: 200px;
  }
}

@media screen and (min-width: 767.5px) and (max-width: 900px) {
  .user-header-wrapper {
    .primary-menu {
      li {
        font-size: 12px;
      }
    }
  }
}
