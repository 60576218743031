.checkout-wrapper {
  height: 100vh;
  .checkout-inner {
    .container {
      max-width: 1152px;
      .left {
        line-height: 0;
      }
      .right {
        max-width: 350px;
        .plan-type {
          border-bottom: 5px solid #87449a !important;
        }
        .StripeElement {
          background: #f3f3f3;
          border-radius: 12px;
          font-size: 18px;
          padding: 20px 10px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .checkout-wrapper {
    height: auto;
    .checkout-inner {
      max-width: 380px;

      .container {
        max-width: 1152px;
        .left {
          line-height: 0;
          img {
            max-height: 300px;
            object-fit: cover;
            object-position: center;
          }
        }
        .right {
          max-width: 350px;
          border-radius: 50px 50px 0px 0px;
          max-width: 100%;
          padding: 0 30px;
          margin-top: -75px;
          background-color: #ffffff;
          .plan-type {
            border-bottom: 5px solid #87449a !important;
            display: inline-block;
            padding: 0 40px;
            margin: 0 auto;
            position: relative;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .checkout-wrapper {
    height: auto;
    .checkout-inner {
      max-width: 100%;

      .container {
        .right {
          max-width: 100%;
          padding: 0 30px;
          .plan-type {
            border-bottom: 5px solid #87449a !important;
            display: inline-block;
            padding: 0 40px;
            margin: 0 auto;
            position: relative;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
    }
  }
}
