@import "../../../assets/scss/abstract/variable";

.upload-content-options {
  .upload-content-options-wrapper {
    .top-heading {
      position: fixed;
      width: 100%;
      max-width: 1470px;
      left: 50%;
      z-index: 1;
      top: 0;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);

      div {
        max-width: calc(50% + 55px);
      }
    }

    .select-options-content {
      width: 75%;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .down-arrow {
        width: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
      }

      .text-area {
        border: 1px solid $color-grey-3;
      }

      .text-area,
      .add-file {
        height: 167px;
      }

      .add-file {
        position: relative;

        .add-photo {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }

    .action-done {
      bottom: 40px;
      left: 27%;
    }

    .custom-editor-wrapper {
      .custom-editor-toolbar {
        background-color: $color-grey-4 !important;

        &::before {
          content: "Instruction";
          font-size: 14px;
          color: #000000;
          margin-right: 60px;
          font-weight: 600;
        }
      }

      .custom-editor {
        min-height: 230px;
        max-height: 230px;
        overflow-y: auto;
      }
    }
  }

  .main-content {
    padding-right: 0px !important;
    padding-bottom: 0px !important;

    .display-img {
      line-height: 0;
      position: relative;
      z-index: 99;

      img {
        min-height: 100vh;
        max-height: 100vh;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .upload-content-options {
    .upload-content-options-wrapper {
      .action-done {
        bottom: 70px;
        left: 25%;
      }

      .top-heading {
        left: 0;
        width: 50%;
        transform: none;

        div {
          // max-width: calc(50% + 38px);
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .upload-content-options {
    .upload-content-options-wrapper {
      .select-options-content {
        width: 100%;
      }

      .top-heading {
        left: 0;
        width: 100%;
        transform: none;

        div {
          // max-width: calc(50% + 38px);
          max-width: 100%;
        }
      }
    }

    .main-content {
      padding-right: 15px !important;
      padding-bottom: 50px !important;
    }
  }
}