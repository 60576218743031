@import "../../../../assets/scss/abstract/variable";
.user-header-wrapper {
  .top-strip,
  .hometop-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  li {
    a {
      color: #999999;
      &:hover,
      &.active {
        color: #883e7f;
        text-shadow: 0px 0px 5px rgb(136, 62, 127);
      }
    }
  }
  .left-part {
    img {
      width: 70px;
    }
  }
  .signup-email {
    .modal-dialog {
      width: 100% !important;
      max-width: 90% !important;

      .modal-content {
        max-height: 90vh;
        overflow-y: auto;
      }
      .form-wraper {
        max-height: none !important;
        height: 100% !important;
      }
      .close-modal-option {
        left: 10px;
        top: 10px;
      }
    }
  }
  .logo {
    width: 200px;
  }
}

@media (max-width: 1200.5px){
  .user-header-wrapper {
    .logo {
      width: 100px;
    }
  }
}

@media (max-width: 767.5px) {
  .user-header-wrapper {
    .signup-email {
      .display-img {
        // margin-top: 50px;
      }
    }
    // .modal {
    //   left: 15px !important;
    // }
  }
}


