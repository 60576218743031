.view-video-wapper {
  .hometop-wrapper {
    display: none !important;
  }

  .flowplayer {
    padding: 0;
    max-height: 530px;
  }

  .fa-video-player .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .suggestions-wapper {
    .suggestion-list {
      .suggestion-detail {
        .img {
          flex: 0 0 40%;
          max-width: 40%;
          margin-right: 10px;
          img {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
.btn-share.dropdown-toggle::after {
  content: none;
}

@media screen and (max-width: 576px) {
  .view-video-wapper {
    .video-box {
      padding-top: 100px;
      iframe {
        height: 260px;
        width: 100%;
      }
    }
  }
}
