@import "../../../assets/scss/abstract/variable";

.recipe-list-page-dashboard {
  .page-item {
    &.active .page-link {
      z-index: 3;
      color: #fff;
      background-color: $color-purple;
      border-color: $color-purple;
    }
    .page-link {
      color: $color-purple;
      border: 1px solid $color-purple;
    }
  }
  .main-content {
    padding-left: 50px !important;
    padding-bottom: 0px !important;
  }
  .recipe-list {
    padding-top: 125px !important;
    .recipe-wrapper {
      overflow: hidden;
      .recipe-img-wrapper {
        img {
          max-height: 225px;
          height: 225px;
          object-fit: cover;
          object-position: center;
        }
      }
      .recipe-content-wrapper {
        height: 200px;
        .recipe-title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .recipe-list-page-dashboard {
    .main-content {
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-bottom: 0px !important;
    }
  }
}

@media (max-width: 767px) {
  .recipe-list-page-dashboard {
    .main-content {
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-bottom: 0px !important;
    }
  }
}
