@import "../../../assets/scss/abstract/variable";
.all-video-wrapper {
    .video-box {
        img {
            height: 187px;
        }
    }
}

.fa-search{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: $color-placeholder;
}

@media (max-width:576px) {
    .all-video-wrapper {
        .video-box {
            img {
                height: 125px;
            }
        }
    }
}

.align-center {
    align-self: center;
}