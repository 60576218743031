.subscribe-for-update {
  .form-group .form-control {
    // input[type="text"] {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    background-color: #ffffff;
    margin-bottom: 0 !important;
    // }

    .subscribe.btn {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  }
  .img-wrapper {
    line-height: 0;
  }
}

@media (max-width: 991px) {
  .subscribe-for-update {
    .form-group .form-control {
      // input[type="text"] {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      height: 100%;
      // }
    }
    .subscribe.btn {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .img-wrapper {
      line-height: 0;
    }
  }
}

@media (max-width: 542px) {
  .subscribe-for-update {
    .form-group .form-control {
      // input[type="text"] {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      height: 100%;
      // }
    }
    .subscribe.btn {
      padding: 10px 15px !important;
    }
    .img-wrapper {
      line-height: 0;
    }
  }
}

