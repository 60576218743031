@import "../../../../assets/scss/abstract/variable";
.announcements-wrapper {
  flex: 0 0 360px;
  z-index: 9;
  line-height: 22px;
  p{
    font-weight: bold;
  }
  .notification-wrapper {
    border: 2px solid $color-grey-4;
    .notifiaction-box {
      height: 186px;
      overflow-y: auto;
      .notification-btn {
        height: 60px;
      }
    }
  }
  .chef-img {
    border: 3px solid $color-light-yellow;
    border-radius: 50%;
  }
   /* width */
   ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $color-grey-2;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $color-grey-3;
  }
}

@media (max-width: 767px) {
  .user-header-wrapper + div {
    padding-top: 150px;
  }
}
