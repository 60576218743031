.carousel-listing-wrapper {
  .add-btn-wrapper {
    .cover-image.btn {
      width: 270px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 10px;
      padding: 0 auto;
      input {
        width: 100%;
      }
    }
    .label {
      margin-top: 0;
    }
    .right {
      .data-exports {
        margin-top: 15px;
        margin-left: 15px;
      }
    }
  }
}

.ant-modal-close-x{
  color: #000;
}

.homepage-popup-table .item-image {
  max-width: 100px;
  width: 100px;
  height: 75px;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 350px) {
  .carousel-listing-wrapper {
    .add-btn-wrapper {
      .right {
        .data-exports {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .carousel-listing-wrapper {
    .add-btn-wrapper {
      .right {
        margin-top: 20px;
      }
      .label {
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 32px);
  }
}
