@import "../../../../assets/scss/abstract/variable";
.UploadCarouselSlide-preview {
border-radius: 12px;
img{
  border-radius: 12px;
}
  .btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $color-blue;
    z-index: 3;
    padding:3% 15%;
  }
}
