.common-login {
    height: 100vh;
    .form-wraper {
        flex-grow: 1;
        max-height: 1024px;
        height: 100vh;
        overflow-y: auto;
        form {
            max-width: 330px;
            margin: 0 auto;
            .social-login-links {
                a {
                    flex-grow: 1;
                }
            }
        }
    }
    .display-img {
        height: 100vh;
        max-height: 1024px;
        @include flex-size(35%);
        img {
            width: 100%;
            height: 100vh;
            max-height: 1024px;
            object-fit: cover;
        }
    }
}

@media (max-width: 767px) {
    .common-login {
        height: 100vh;
        .form-wraper {
            @include flex-size(100%);
            max-height: none;
            height: auto;
            overflow-y: auto;
            form {
                max-width: 325px;
                margin: 0 auto;
                .social-login-links {
                    a {
                        flex-grow: 1;
                    }
                }
            }
        }
        .display-img {
            max-height: 250px;
            @include flex-size(100%);
            img {
                max-height: 250px;
                height: 250px;
                object-fit: cover;
                object-position: center;
                width: 100%;
            }
        }
    }
}

// @media (max-height:767px) and (min-width:767px) {
//     .common-login {
//         .form-wraper {
//             align-items: flex-start !important;
//             padding: 130px 0;
            
//         }
       
//     }
// }