
.welcome-box{
    line-height:0;
    h1{
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        text-shadow: 0px 8px 19px rgba(0, 0, 0, 0.24);
    }
    img{
        height: 100vh;
        object-fit: cover;
        object-position: center;
    }
}

@media screen  and (max-width: 767px){
    .welcome-box{
        h1{
            font-size: 30px !important;
        }
        img{
            height: 100vh;
            object-fit: cover;
            object-position: center;
        }
    } 
}