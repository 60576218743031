.sidebar-menu{
    .menu-content{
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@media  (max-width: 767px){
    .sidebar-menu{
        .menu-content{
            position: static;
            -ms-transform: translateY(0);
            transform: translateY(0);
        }
    }
}