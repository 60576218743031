.social-media-follow-options {
  .socia-media-content {
    .social-media-icon {
      top: -51px;
      left: -12px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
      padding: 20px 25px;
      img {
        max-width: 40px;
        height: 38px;
      }
    }
  }
}

@media (max-width: 991px) {
  .social-media-follow-options {
    .socia-media-content {
      .social-media-icon {
        top: -20px;
        left: -20px;
        padding: 5px !important;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 35px;
        }
      }
    }
  }
}

@media (max-width: 767) {
  .social-media-follow-options {
    .socia-media-content {
      max-width: 250px;
    }
  }
}
