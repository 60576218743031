.watch-tutorials {
  .premium-icon {
    display: none;
    &.active {
      top: 7px;
      left: 7px;
      z-index: 4;
      display: block;
    }
  }
  .watch-tutorials-banner {
    position: relative;
    height: 400px;
    .react-player {
      position: absolute;
      left: 0;
      top: 0;
      iframe,video{
        height: 400px !important;
        width: 100% !important;
      }
    }
    .img {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 400px;
        width: 100%;
        opacity: 0.6;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
      }
    }
  }
  .tutorial-slider-wrapper {
    .slick-list {
      margin: 0 -10px;
      .slick-slide {
        padding: 0 10px;
        .tutorials-wrapper {
          overflow: hidden;
          .tutorials-img-wrapper {
            img {
              max-height: 250px;
              height: 250px;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .tutorials-content-wrapper {
            height: 200px;
            .recipe-title {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .slick-next {
    right: -28px !important;
  }
  .slick-prev {
    left: -28px !important;
  }
}
