.flash-sale-slider {
  .sale-preview {
    .img-box {
      max-width: 429px;
      height: 316px;
      overflow: hidden;
      &:hover {
          img {
              transform: scale(1.1);
              transition: all 300ms ease-in;
          }
      }
  }
  .flash-sale-heading{
    max-width: 106px;
    bottom: 22px;
    left: 22px;
    z-index: 1;
  }
    .tag {
      bottom: 26px;
      z-index: 1;
      left: 7px;
      border-radius: 0px 3px 3px 0px;
    }
  }
}

@media screen and (max-width: 767px) {
  .flash-sale-slider {
    .sale-preview {
      .img-box {
        max-width: 100%;
      }
    }
  }
}
